.chooseTestContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.chooseTestButton {
    display: flex;
    justify-content: center;
}

.chooseTestButton button {
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.chooseTestButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.chooseTestContent {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chooseTestContentChild {
    min-width: 270px;
    margin-bottom: 12px;
}

.chooseTestH5 {
    margin: 0;
}

.chooseTestElem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
}

.chooseTestElem label {
    margin: 10px 10px;
    font-style: italic;
    color: #034459;
    font-size: 18px;
}

.chooseTestElem input {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.chooseTestElem input[type=checkbox] {
    accent-color: #005773;
}
