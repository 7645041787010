.LoginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}

.loginContent {
    width: 440px;
    height: 620px;
    padding: 0 25px 15px 25px;
    background-color: #034459;
    border-radius: 4px;
    box-shadow: 4px 4px 6px #0344598a;
    -webkit-box-shadow: 4px 4px 6px #0344598a;
    -moz-box-shadow: 4px 4px 6px #0344598a;
    position: relative;
}

.buttonContainerLogin {
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 40px;
}

.buttonContainerLogin button {
    height: 100%;
    width: 100%;
    border: none;
    cursor: pointer;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.loginBtn {
    width: 50%;
    color: #fff;
    text-transform: none;
}

.loginBtn button {
    background: #034459;
    color: #fff;
    font-size: 16px;
}

.createAccountBtn {
    width: 50%;
    color: #fff;
    text-transform: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.createAccountBtn button {
    background: #005773;
    color: #fff;
    font-size: 16px;
}

button:focus {
    outline: none !important;
}

.formLogin {
    margin-top: 3rem;
    /*height: 250px;*/
}

.formLogin form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.formLogin h4 {
    color: #9b9b9b;
    margin-top: 4px;
    font-size: 18px;
}

.formLogin input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    border: 1px solid #CFCFCF;
    outline: 0;
    display: inline-block;
    padding: 0.7em 1em;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    transition: all .2s;
}

.inputField:focus {
    box-shadow: 0 0 0 2px #0089b6;
    transition: all .2s;
}
.loginBtnSubmit{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.loginBtnSubmit button {
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.loginBtnSubmit button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.forgotPassword {
    width: 130px;
    /*position: absolute;*/
    /*bottom: 0.7rem;*/
}

.forgotPassword h5 {
    margin-top: 1rem;
    color: #28b8f1;
    text-decoration: none;
    transition: all 0.2s;
    font-size: 16px;
}
.forgotPassword h5:hover {
    text-decoration: none;
}

.forgotPassword h5:hover {
    color: #fff;
    transition: all 0.2s;
}

.errorMessage {
    color: red;
    margin-top: 1rem;
    text-align: center;
    max-height: 100px;
    overflow: auto;
}
.successMessage {
    color: greenyellow;
    margin-top: 1rem;
    text-align: center;
    max-height: 100px;
    overflow: auto;
}

.loginFormFooter {
    bottom: 7px;
    position: absolute;
    color: white;
    right: 7px;
    font-size: 16px;
    width: 200px;
    height: 40px;
}

.loginFormFooter img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-right: 7px;
}

/*-------------------- logOutContainer ---------------------*/
.logOutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logOutMessage {
    color: red;
    margin: 10px;
}
/*-------------------- forgotPassword ---------------------*/
.forgotPasswordMessage{
    margin-top: 3px;
    color: #034459;
    max-height: 80px;
    overflow: auto;
}

.formForgotPassword{
    height: 250px;
}