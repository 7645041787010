body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*----------------------------- table nest css ----------------------------*/
td {
    padding: 0 !important;;
    margin: 0 !important;
    vertical-align: middle !important;
    padding-left: 5px !important;
}

.migrationLogsClass {
    font-size: 18px;
    padding: 3px 3px 3px 5px !important;
    overflow: auto;
    vertical-align: middle !important;
}

.reportFileCenter {
    text-align: center !important;
}

.overFlowHiddenRow {
    min-width: 150px !important;
    overflow: hidden !important;
}

.performedContainers ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #eeeeee;
}

.performedContent ::-webkit-scrollbar-thumb:horizontal {
    background: #c7c7c7;
    border-radius: 10px;
}

.pagination {
    justify-content: flex-end !important;
}

.react-bootstrap-table-pagination {
    margin: 0;
}

.dropdown-menu {
    top: unset !important;
    bottom: 100%;
}


.text-area {
    display: list-item;
    margin-left: 1em;
    outline: none;
}

.text-area div {
    display: list-item;
}

.ReactModalPortal .formLogin {
    width: 82%;
    /* height: 700px; */
    margin: 0;
}

.ReactModalPortal .formLogin form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 60%;
    padding-top: 20px;
}

.css-b62m3t-container {
    width: 100% !important;
}

.css-1fdsijx-ValueContainer {
    font-size: 16px !important;
}

.css-1dimb5e-singleValue {
    font-size: 16px !important;
}

.css-hlgwow div {
    font-size: 16px !important;
}

.labelingHeaderDatePickers {
    display: flex;
    padding: 0.6rem 1rem 1rem 1rem;
}

.labelingPickerElem {
    display: flex;
    justify-content: center;
    align-items: center;
}

.labelingPickerElem p {
    display: block;
    width: 125px;
}

.labelingPickerElem input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    border: 1px solid #CFCFCF;
    outline: 0;
    display: inline-block;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    transition: all .2s;
    padding: 0.3em;
}

.labelingPickerElem input:focus {
    box-shadow: 0 0 0 2px #0089b6;
    transition: all .2s;
}
