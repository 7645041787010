p {
    margin: 0 !important;
    padding: 0 !important;;
    box-sizing: border-box !important;;
}

.personalInformationContainer .loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.personalInformation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 215px;
    width: 100%;
}

.personalInformationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    flex-direction: column;
}

.personalInformationContent {
    display: flex;
}

.buttonsContainer {
    display: flex;
}

.personalInfoContent {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
}

.personalInfoElements {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.accountInfoContent {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}


.accountInfoContent .accountInfoEl {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
}

.insideFormContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.insideFormContent .personalInfoBtn button {
    width: 200px;
}

.closeEditButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.personalLogo {
    width: 150px;
}

.personalLogo img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.accountInfoContent {
    color: #034459;
    font-size: 18px;
    width: 100%;
}

.accountInfoContent span {
    color: #777777;
    font-style: italic;
    font-size: 18px;
    min-width: 100px;
}

.accountInfoEl > p {
    padding-left: 5px !important;
}

.accountInfoEl > input {
    padding-left: 5px !important;
    margin-left: 5px !important;
    height: 25px;
}

.footerPersonalInfo {
    display: flex;
    align-items: center;
}

.personalInfoChangePassContent {
    display: flex;
    flex-direction: column;
    width: 330px;
    padding-right: 7px;
}

.personalInfoChangePassContent input {
    margin: 3px 0;
}

.personalInfoBtn {
    display: flex;
    justify-content: center;
}

.personalInfoBtn button {
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    height: 42px;
    margin-top: 2px;
}

.personalInfoBtn button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.personalInfoChangePassContent input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    border: 1px solid #CFCFCF;
    outline: 0;
    display: inline-block;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    transition: all .2s;
    padding: 0.3em;
}

.personalInfoChangePassContent .inputField:focus {
    box-shadow: 0 0 0 2px #0089b6;
    transition: all .2s;
}

.accountInfoContent button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.accountInfoContent input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    border: 1px solid #CFCFCF;
    outline: 0;
    display: inline-block;
    /*width: 100%;*/
    border-radius: 3px;
    font-size: 16px;
    transition: all .2s;
    padding: 0.3em;
}

.accountInfoContent .inputField:focus {
    box-shadow: 0 0 0 2px #0089b6;
    transition: all .2s;
}
.accountInfoContent .selectCountry * {
    padding: 0!important;
    margin: 0!important;
}
.accountInfoContent .selectCountry p {
    padding-left: 5px !important;
}

.formBarButtons {
    display: flex;
    justify-content: space-around;
}

.formBarButtons button {
    margin: 5px;
}

.pencilIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    background-color: #e1e1e1;
    border-radius: 50%;
    color: #005773;
}

.pencilIconContainer:hover {
    background-color: #cecece;
    cursor: pointer;
    color: #034459;
}

.mistakeSpan {
    padding-top: 10px;
    text-align: center;
    color: red;
    margin: 0 auto;
    display: block;
    max-height: 110px;
    overflow: auto;
}

.successSpan {
    padding-top: 10px;
    text-align: center;
    color: #39e71c;
    margin: 0 auto;
    display: block;
    max-height: 110px;
    overflow: auto;
}

.personalTableContent {
    display: flex;
    width: 98%;
    margin: 1rem auto 0;
    max-height: 76vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;

    /*width: 98%;*/
    /*margin: 1rem auto 0;*/
    /*max-height: 80vh;*/
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
}

.sizePagination {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 0 auto;
    align-items: flex-start;
    padding-top: 5px;
    height: 40px;
}
