.performedContainers {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.performedContent {
    display: flex;
    width: 98%;
    margin: 1rem auto 0;
    max-height: 76vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;

    /*width: 98%;*/
    /*margin: 1rem auto 0;*/
    /*max-height: 80vh;*/
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
}

.sizePagination{
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 0 auto;
    align-items: flex-start;
    padding-top: 5px;
    height: 40px;
}

.performedElem {
    display: flex;
    justify-content: space-between;
    border: 1px solid silver;
    padding: 1rem;
    margin: 5px 0;
    border-radius: 5px;
}
.availableTestError{
    display: flex;
    justify-content: center;
}

.performedNewTestButton {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.performedNewTestButton button {
    padding: 0.75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    /*position: relative;*/
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
}

.performedNewTestButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}
.refreshButton{
    width: 100px;
    position: absolute;
    bottom: 63px;
    cursor: pointer;
}
