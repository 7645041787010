.createAccountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}

.createAccountContent {
    width: 680px;
    height: 620px;
    padding: 0 25px 15px 25px;
    background-color: #034459;
    border-radius: 4px;
    box-shadow: 4px 4px 6px #0344598a;
    -webkit-box-shadow: 4px 4px 6px #0344598a;
    -moz-box-shadow: 4px 4px 6px #0344598a;
    position: relative;
}

.buttonContainerCA {
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 40px;
}

.buttonContainerCA button {
    height: 100%;
    width: 100%;
    border: none;
    cursor: pointer;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.loginBtn {
    width: 50%;
    color: white;
    text-transform: none;
}

.loginBtn button {
    background: #005773;
    color: #eee;
    font-size: 16px;
}

.createAccountBtn {
    width: 50%;
    color: white;
    text-transform: none;
}

.createAccountBtn button {
    background: #034459;
    color: #eee;
    font-size: 16px;
}

.formCA {
    margin-top: 25px;
}

.formCA input, select {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    border: 1px solid #CFCFCF;
    outline: 0;
    display: inline-block;
    padding: 0.5em 1em;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    transition: all .2s;
}

.formCA select {
    cursor: pointer;
}

.formParts {
    display: flex;
    justify-content: space-between;
}

.formLeftPart {
    width: 49%;
}

.formRightPart {
    width: 49%;
}


.inpElem {
    margin: 0 auto 5px;
}

.inputField:focus {
    box-shadow: 0 0 0 2px #0089b6;
    transition: all .2s;
}

.submitBtnCA {
    display: flex;
    justify-content: space-between;
}

.submitBtnCA button {
    height: 48px;
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.submitBtnCA button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.mistakeSpan {
    padding-top: 10px;
    text-align: center;
    color: red;
    margin: 0 auto;
    display: block;
    max-height: 110px;
    overflow: auto;
}

.accountCreated {
    padding-top: 10px;
    text-align: center;
    color: #00ff04;
    margin: 0 auto;
    display: block;
    max-height: 85px;
    overflow: auto;
}

.loginFormFooter {
    bottom: 7px;
    position: absolute;
    color: white;
    right: 7px;
    font-size: 16px;
    width: 200px;
    height: 40px;
}

.loginFormFooter img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding-right: 7px;
}

.reCAPTCHAContainer > div {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
