.invoicesContainers {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.invoicesContent {
    display: flex;
    width: 98%;
    /*margin: 1rem auto 0;*/
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
}

.paymentMakeButton {
    margin-top: 10px;
    border: none;
    font-size: 18px;
    padding: 7px;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 silver;
    background: #00577333;

}

.paymentMakeButton a {
    color: #005773;
}

.sizePagination {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 0 auto;
    align-items: flex-start;
    padding-top: 5px;
    height: 40px;
}

