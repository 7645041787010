.newTestContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.newTestTextarea {
    overflow: auto;
    border: 1px solid #dadada;
    box-shadow: 0 0 20px 0 #b5b5b5;
    padding: 15px;
    border-radius: 5px;
    margin-top: 1rem;
    resize: both;
}

.newTestButton {
    display: flex;
    justify-content: center;
}

.newTestButton button {
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.newTestButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}