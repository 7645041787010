/*---------------------------------------------------- SlideBar ----------------------------------------------------*/
.sideBarDashboard {
    position: relative;
    min-width: 17rem;
    height: 100%;
    min-height: 100vh;
    background-color: #034459;
}

.fixedContent {
    position: fixed;
    max-width: 17rem;
    width: 100%;
}


.hideSlideBar .sideBarOptionsTitle, .hideSlideBar .sideBarOptionsIconRight, .hideSlideBar .sideBarItemTitle {
    display: none !important;
}

.sideBarUserContent a {
    display: flex;
    color: #eee;
    text-decoration: none !important;
}

.sideBarUserContent a:hover {
    color: #eee;
    text-decoration: none !important;;
}

.sideBarOptions {
    padding-top: 5vh;
    background-color: #034459;
    height: 100vh;
}

.sideBarOptions a {
    display: flex;
    color: #eee;
    text-decoration: none !important;
}

.sideBarOptions a:hover {
    color: #eee;
    text-decoration: none !important;;
}

.sideBarBottomLogo {
    position: fixed;
    bottom: 15px;
    left: 2rem;
    width: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.sideBarBottomLogo .imageLogo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
}

.sideBarItem {
    width: 100%;
    height: 75px;
    display: flex;
    text-align: start;
    color: #eee;
    padding: 0.5rem 0.3rem 0.5rem 1rem;
    align-items: center;
    background: #005773;
}

.sideBarOptionsItem {
    width: 100%;
    display: flex;
    text-align: start;
    color: #eee;
    padding: .5rem .3rem .5rem 1rem;
    cursor: pointer;
    position: relative;
}

.sideBarOptionsItem:hover {
    background-color: #005773;
}

.sideBarItemIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    background-color: silver;
    border-radius: 50%;
}

.sideBarItemTitle {
    display: -webkit-box;
    justify-content: center;
    align-items: center;
    padding-left: .8rem;
    max-width: 155px;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.sideBarOptionsIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 2rem;
}

.sideBarOptionsIcon svg {
    width: 35px;
}

.sideBarOptionsIconRight {
    position: absolute;
    right: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 2rem;
}

.sideBarOptionsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: .8rem;
}

/*--------------------------------------------------------- Header ---------------------------------------------------------*/

.fullContent {
    width: 100%;
}

.headerContainer {
    display: flex;
    font-family: system-ui, Segoe UI, Arial, sans-serif;
}

.headerRow {
    width: 100%;
}

.headerContent {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    border-bottom: 1px solid #dedede;
}

.headerTitle {
    padding-top: 7px;
    /*width: 28%;*/
    margin: 0;
    color: #838383;
}

/*...........Header Search..........*/

.logoutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    margin-right: 15px;
    border-radius: 50px;
    color: #c3c3c3;
    box-shadow: 0 0 5px 0 #c0c0c082;
    transition: all .2s;
}

.logoutButton:hover {
    cursor: pointer;
    background-color: #ffffff !important;
    color: #000000 !important;
    transition: all .2s;
}

/*---------------------------------------------------- DashboardContainer ----------------------------------------------------*/

.dashboardContainer {
    display: flex;
    font-family: system-ui, Segoe UI, Arial, sans-serif;
}

.dashboardTableContent {
    width: 100%;
    height: 100%;
    margin: 0 1rem 1rem 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.propsChildDashboard {

}

