.predictValidationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    position: relative;
}

.predictValidationContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.predictValidationPart {
    display: flex;
    flex-wrap: wrap;
    max-height: 80vh;
    overflow: auto;
    justify-content: center;
    margin: 10px 0
}

.predictValidationElem {
    /*width: 260px;*/
    /*height: 180px;*/
    padding: 15px;
    box-shadow: 0 0 10px 0 silver;
    margin: 5px;
    border-radius: 5px;
}

.predictValidationTitle {

}

.validationResultInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
}

.totalPrice {
    position: absolute;
    top: 1px;
    padding: 7px;
    color: #034459;
    font-size: 18px;
    font-weight: bold;
    margin: 0 auto;
}

.validationResultInfo span {
    color: #034459;
    font-weight: bold;
}

.validationResultButton {

}

.validationResultButton {
    display: flex;
    justify-content: center;
}

.validationResultButton button {
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.validationResultButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}