.makePaymentContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 94%;
    margin: .4rem auto 0;
    /*max-height: 80vh;*/
    overflow: auto;
}

.makePaymentElem {
    display: flex;
    justify-content: space-between;
    border: 1px solid silver;
    padding: 1rem;
    margin: 6px 0;
    border-radius: 5px;
    flex-direction: column;
}

.makePaymentElem .description {
    width: 90%;
    padding-right: 5px;
}

.makePaymentElem .descriptionElem {
    color: #034459;
    padding-left: 9px;
    font-style: italic;
}

.makePaymentElem .price {
    display: flex;
    align-items: center;
}

.makePaymentElem h5 {
    font-size: 17px;
}

.makePaymentError {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.bodyContainer {
    max-height: 78vh;
    overflow: auto;
}

.selectSmiles {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
}

.makePaymentAllPrices {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #00577333;
    border-radius: 4px;
}

.makePaymentPrice {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.show {
    display: flex;
    padding: 10px;
    align-items: flex-end;
}

.showHidePrices {
    display: none !important;
}

.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox label {
    display: inline-block;
    padding: 3px 10px;
    margin: 0 !important;
    font-weight: 500;
    color: #034459;
}

.checkbox input {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.checkbox input[type=checkbox] {
    accent-color: #005773;
}

.selectMouth {
    display: flex;
    justify-content: flex-end;
}

.selectMouth .checkbox {
    display: flex;
    justify-content: flex-end;
}

.selectMouthElement {
    display: flex;
    align-items: center;
    /*width: 60px;*/
    justify-content: flex-end;
}

.selectMouthElement label {
    padding-right: 5px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    /*position: fixed;*/
    bottom: 0;
    margin-bottom: 20px;
}

.footerLeft {
    display: flex;
    justify-content: space-between;
    min-width: 190px;
    font-weight: 500;
    font-size: 18px;
    color: #034459;
}

.makePaymentButton {
    min-width: 180px;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.makePaymentButton button {
    /*margin-top: 2.2rem;*/
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.makePaymentButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}

.viewInvoice {
    margin: 100px auto;
    display: flex;
    justify-content: center;
    color: #034459;
    font-size: 17px;
}
