
.availableTestContainers {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.availableTestContent {
    width: 94%;
    margin: 1rem auto 0;
    max-height: 80vh;
    overflow: auto;
}

.availableTestElem {
    display: flex;
    justify-content: space-between;
    border: 1px solid silver;
    padding: 1rem;
    margin: 5px 0;
    border-radius: 5px;
}

.availableTestElem .description {
    width: 78%;
    padding-right: 5px;
}

.availableTestElem .descriptionElem {
    color: #034459;
    padding-left: 9px;
    font-style: italic;
}

.availableTestElem .price {
    display: flex;
    align-items: center;
}

.availableTestElem h5 {
    font-size: 17px;
}

.availableTestError {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.availableTestNewTestButton {
    display: flex;
    justify-content: center;
}

.availableTestNewTestButton button {
    margin-top: 1rem;
    padding: .75rem 2rem;
    color: #fff;
    background-color: #005773;
    font-size: 16px;
    transition: all 0.2s;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.availableTestNewTestButton button:hover {
    box-shadow: 0 0 5px 0 rgba(38, 180, 236, 0.81);
}
